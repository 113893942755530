const commonMethods = {
    // "PZZT": 0,//配置状态

    // 暂停
    showPauseBtn(row) {
        if (Number(row.PZZT) === 2) {
            return true;
        } else {
            return false;
        }
    },
    // 开始
    showStartBtn(row) {
        if (Number(row.PZZT) === 1) {
            return true;
        } else {
            return false;
        }
    },
    // 配置
    showConfigBtn(row) {
        if (Number(row.PZZT) === 0) {
            return true;
        } else {
            return false;
        }
    },
    // 查看
    showReadBtn(row) {
        if (Number(row.PZZT) === 2) {
            return true;
        } else {
            return false;
        }
    },
    // 修改
    showEditBtn(row) {
        if (Number(row.PZZT) === 1) {
            return true;
        } else {
            return false;
        }
    },
};
export { commonMethods };