<template>
    <div class="warning-config-grid">
        <sn-table-group
            class="table"
            :tableGroupAttributes="tableGroupAttributes"
            :commonMethods="commonMethods"
            @handleChange="handleChange"
        ></sn-table-group>
    </div>
</template>
<script>
import { commonData } from "../warning_common/sn-table-group-common";
import { selfData } from "./model/sn-table-group";
import commonMethodMixin from "../warning_common/commonMethodMixin";
import { mapActions } from "vuex";
import { commonMethods } from "./model/table-methods";
export default {
    name: "warning_config_grid",
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            commonMethods,
            selfData,
            infoPage: "warning_config_form",
            // 筛选项
            keyword: "", // 关键字
            sjlxValue: "", // 数据类型选择的值
            exportFunId: "yj003",
            exportName: "预警配置",
        };
    },
    mounted() {
        this.getSjlxist();
    },
    methods: {
        ...mapActions([
            "GetYjpzDataList", // 获取预警配置列表
            "UpdateYjpzPzzt", // 修改配置状态
        ]),
        async getTableData() {
            // 处理筛选
            this.sjlxValue = "";
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item, index) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "SJLX") {
                            this.sjlxValue = item.value;
                        }
                    }
                });
            }

            this.tableGroupAttributes.loading = true;
            let res = await this.GetYjpzDataList({
                keyWords: this.keyword, // String 关键字；预警配置方案名称
                sjlx: this.sjlxValue,
                pageIndex: this.tableGroupAttributes.snPage.currentPage, // Int 页数
                pageSize: this.tableGroupAttributes.snPage.tiaoshu, // Int 页面数据量
            });
            this.tableGroupAttributes.snTable.table.data =
                res && res.DATA ? res.DATA : [];
            this.tableGroupAttributes.snPage.count =
                res && res.TOTAL ? Number(res.TOTAL) : 0;
            this.tableGroupAttributes.loading = false;
        },
        // 获取数据类型列表
        getSjlxist() {
            this.tableGroupAttributes.snSearch.selectData[0].list = [];
            let result = [
                {
                    CODE: "1",
                    NAME: "数值类",
                },
                {
                    CODE: "2",
                    NAME: "枚举类",
                },
            ];
            if (result && result.length > 0) {
                this.tableGroupAttributes.snSearch.selectData[0].list = result;
            }
        },
        columnConfigClick(value, type) {
            // type 0配置 1修改 2查看
            let id = value && value.length > 0 ? value[0].ID : "";

            this.$router.push({
                name: this.infoPage,
                query: {
                    type: type,
                    id: id,
                    data: JSON.stringify(value[0]),
                },
            });
        },
        async columnPauseClick(value) {
            let yjpzId = value && value.length > 0 ? value[0].ID : "";
            let pzzt = "1";
            this.updateYjpzPzzt(yjpzId, pzzt);
        },
        async columnStartClick(value) {
            let yjpzId = value && value.length > 0 ? value[0].ID : "";
            let pzzt = "2";
            this.updateYjpzPzzt(yjpzId, pzzt);
        },
        async updateYjpzPzzt(yjpzId, pzzt) {
            let result = await this.UpdateYjpzPzzt({
                yjpzId: yjpzId, // 预警配置ID
                pzzt: pzzt, // 配置状态；0未配置，1已暂停，2实施中
            });
            console.log(4444555666, result);
            if (result.ISSUCCESS) {
                this.$message({
                    type: "success",
                    message: result.RESULTDESCRIPTION,
                });
                this.getTableData();
            } else {
                this.$message({
                    type: "error",
                    message: result.RESULTDESCRIPTION,
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.warning-config-grid {
    width: 100%;
    height: 100%;
    .table {
        width: 100%;
        height: 100%;
    }
}
</style>
