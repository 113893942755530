const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "对象类型",
                prop: "DXLXMC",
            }, {
                label: "对象",
                prop: "DXMC",
            }, {
                label: "数据类型",
                prop: "SJLX",
                transList: {
                    1: '数值类',
                    2: '枚举类',
                },
            }, {
                label: "预警负责人",
                prop: "CZFZRMC",
            }, {
                label: "一级预警信息接收人",
                prop: "ONEYJJSR",
            }, {
                label: "二级预警信息接收人",
                prop: "TWOYJJSR",
            }, {
                label: "三级预警信息接收人",
                prop: "THREEYJJSR",
            }, {
                label: "四级预警信息接收人",
                prop: "FOURYJJSR",
            }, {
                label: "状态",
                prop: "PZZTNAME",
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnPause',
                    text: '暂停',
                    color: 'green',
                    showMethod: 'showPauseBtn',
                }, {
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnStart',
                    text: '开始',
                    color: 'green',
                    showMethod: 'showStartBtn',
                }, {
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnConfig',
                    text: '配置',
                    color: 'green',
                    showMethod: 'showConfigBtn',
                }, {
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnRead',
                    text: '查看',
                    // paddingLeft: '20px',
                    color: 'green',
                    showMethod: 'showReadBtn',
                }, {
                    isShow: true,
                    btnType: 'text',
                    operateType: 'columnEdit',
                    text: '修改',
                    // paddingLeft: '20px',
                    color: 'green',
                    showMethod: 'showEditBtn',
                }],
                width: 150,
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '数据类型',
            clearable: true,
            placeholder: "请选择数据类型",
            list: [],
            optionLabel: 'NAME',
            optionValue: 'CODE',
            value: 'SJLX',
            operateType: 'search',
            isShow: true
        }],
    },
    snButton: {
        buttonData: [{
            //     isShow: true,
            //     btnType: 'button',
            //     operateType: 'buttonNew',
            //     name: '新建',
            //     round: true,
            //     backColor: '#28ccd9',
            // }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };